import { FaLink } from '@react-icons/all-files/fa/FaLink';
import { Link } from 'gatsby';
import React, { FC } from 'react';

const NotFoundPage: FC = () => {
    return (
        <>
            <div className="h-screen w-screen flex items-center">
                <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
                    <div className="max-w-md">
                        <div className="text-5xl font-dark font-bold">404</div>
                        <p className="text-2xl md:text-3xl font-light leading-normal">
                            Inhalt konnte nicht gefunden werden
                        </p>
                        <p className="mb-8">Bestimmt findet man auf der Hauptseite ganz viel mehr</p>

                        <button
                            type="button"
                            className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700"
                        >
                            <Link to="/">Home</Link>
                        </button>
                    </div>
                    <div className="max-w-lg">
                        <FaLink size="15rem" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFoundPage;
